/* jquery plugin to scroll window to the top */
$(function() {
    var up_timer = 0;
    var viewportLeft;
    var viewportTop;

    $.getPosition = function() {
        viewportLeft = document.body.scrollLeft || document.documentElement.scrollLeft;
        viewportTop = document.body.scrollTop || document.documentElement.scrollTop;
    };

    $.pageup = function(x, y) {
        if (up_timer) {
            clearTimeout(up_timer);
        }
        if (y >= 1) {
            $.getPosition();
            var divisionY = (viewportTop - (viewportTop / 5));
            var Y = Math.floor(divisionY);
            window.scrollTo(viewportLeft, Y);
            up_timer = setTimeout("$.pageup(" + viewportLeft + "," + Y + ")", 2);
        } else {
            window.scrollTo(viewportLeft, 0);
            clearTimeout(up_timer);
        }
    };

    $.scrollup = function() {
        $.getPosition();
        $.pageup(viewportLeft, viewportTop);
    };
});