import ObjectStore from './object-store';

export default class ClassStore extends ObjectStore {
  /**
     * @constructor
     * @extends {LIQUID.stores.ObjectStore}
     * @param {object} options
     */
  constructor(options) {
    super(options);
  }

  /**
     * @param {string} collectionName
     * @param {class} obj
     */
  create(collectionName, obj) {
    obj = super.create(collectionName, obj.serialize());
    return new this.options.schema[collectionName](obj);
  }

  /**
     * @param {string} collectionName
     * @param {object} obj
     */
  update(collectionName, obj) {
    obj = obj.serialize();
    obj = super.update(collectionName, obj);
  }

  /**
     * @param {string} collectionName
     * @param {Function} fn (item, index)
     */
  find(collectionName, fn) {
    const ret = [];
    for (let i = 0; i < this._store[collectionName].length; i++) {
      const item = this._store[collectionName][i];
      if (fn(item, i)) {
        ret.push(this.deserializeObject(collectionName, item));
      }
    }
    return ret;
  }

  /**
     * @param {string} collectionName
     * @return {Array.<object>}
     */
  all(collectionName) {
    return this.find(collectionName, () => true);
  }

  /**
     * @param {string} collectionName
     * @param {number} startIndex
     * @param {number} endIndex
     * @return {Array.<object>}
     */
  slice(collectionName, startIndex, endIndex) {
    return this.find(collectionName, (item, index) => index >= startIndex && index < endIndex);
  }

  /**
     * expose base javascript objects
     * @param {string} collectionName
     * @param {Function} fn (item, index)
     * @return {Array.<object>}
     */
  findObjects(collectionName, fn) {
    return super.find(collectionName, fn);
  }

  /**
     * expose base javascript objects
     * @param {string} collectionName
     * @return {Array.<object>}
     */
  allObjects(collectionName) {
    return super.all(collectionName);
  }

  /**
     * take raw objects and store as is
     * @param {string} collectionName
     * @param {Array.<object>} objects
     */
  setCollectionObjects(collectionName, objects) {
    this.clearCollection(collectionName);
    objects.forEach((obj) => {
      super.create(collectionName, obj, true);
    });
    this.options.onChange && this.options.onChange();
  }

  /**
     * stores class instances
     * @param {string} collectionName
     * @param {Array.<object>} objects
     */
  setCollection(collectionName, objects) {
    this.clearCollection(collectionName);
    objects.forEach((obj) => {
      super.create(collectionName, obj.serialize(), true);
    });
    this.options.onChange && this.options.onChange();
  }

  /**
     * @param {string} collectionName
     * @return {object|null}
     */
  getFirstObject(collectionName) {
    const obj = super.getFirstObject(collectionName);
    return obj && this.deserializeObject(collectionName, obj);
  }

  /**
     * @param {string} collectionName
     * @param {number} id
     * @return {object}
     */
  getObjectById(collectionName, id) {
    const obj = super.getObjectById(collectionName, id);
    return obj && this.deserializeObject(collectionName, obj);
  }

  /**
     * @param {string} collection
     * @param {object} o
     */
  deserializeObject(collection, o) {
    /**
         * @type {LIQUID.stores.Serializable}
         */
    const item = new this.options.schema[collection](o);
    return item;
  }
}
