import ObjectStore from '../stores/object-store';
import NetworkError from '../errors/network-error';
import KnownServerError from '../errors/known-server-error';
import TimeOutError from '../errors/time-out-error';
import Settings from '../settings';

export default class Base {
  /**
     * Actions are the place to
     * - do any async calls
     * - set the domain stores
     * - caching logic
     * - model prep work
     * @constructor
     * @param {object} options
     */
  constructor(options) {
    /**
         * @type {object}
         */
    this.options = options;
  }

  /**
     * @param {string} url
     * @param {object} data
     * @param {number} timeout
     * @return {LIQUID.actions.Promise}
     */
  get(url, data, timeout) {
    data = ObjectStore.toJSON(data || {});
    return this.ajax(url, 'GET', data, false, timeout);
  }

  /**
     * @param {string} url
     * @param {object} data
     * @param {boolean} isWithCredentials
     * @return {LIQUID.actions.Promise}
     */
  getCrossDomain(url, data, isWithCredentials) {
    data = ObjectStore.toJSON(data || {});
    const P = new Settings().Promise;
    return new P((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function getCrossDomainOnLoad() {
        resolve(JSON.parse(xhr.responseText));
      };
      xhr.error = function getCrossDomainError() {
        reject(new NetworkError(data, xhr));
      };
      xhr.abort = function getCrossDomainAbort() {
        reject(new NetworkError(data, xhr));
      };
      const queryString = this.stringifyData(data);
      url = queryString ? `${url}?${queryString}` : url;
      xhr.open('GET', url, true);
      if (isWithCredentials) {
        xhr.withCredentials = true;
      }
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.send();
    });
  }

  /**
     * @param {string} url
     * @param {object} data
     * @param {number} timeout
     * @return {LIQUID.actions.Promise}
     */
  post(url, data, timeout) {
    data = ObjectStore.toJSON(data || {});
    return this.ajax(url, 'POST', data, false, timeout);
  }

  /**
     * @param {string} url
     * @param {object} data
     * @param {number} timeout
     * @return {LIQUID.actions.Promise}
     */
  postForm(url, data, timeout) {
    data = ObjectStore.toJSON(data || {});
    return this.ajax(url, 'POST', data, true, timeout);
  }

  /**
     * @param {string} url
     * @param {object} data
     * @param {number} timeout
     * @return {LIQUID.actions.Promise}
     */
  put(url, data, timeout) {
    data = ObjectStore.toJSON(data || {});
    return this.ajax(url, 'PUT', data, false, timeout);
  }

  /**
     * dirty XMLHttpRequest to handle our current ajax
     * @param {string} url
     * @param {string} type
     * @param {object} data
     * @param {number} timeout
     * @param {boolean|string} isForm
     * @return {LIQUID.actions.Promise}
     */
  ajax(url, type, data, isForm, timeout) {
    const P = new Settings().Promise;
    return new P((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function ajaxOnLoad() {
        try {
          const response = JSON.parse(xhr.responseText);
          if (response.meta.error) {
            reject(new KnownServerError(data, response));
          } else {
            resolve(response.data);
          }
        } catch (e) {
          reject(new NetworkError(data, xhr));
        }
      };
      xhr.ontimeout = function ajaxOnTimeout() {
        reject(new TimeOutError(data, xhr));
      };
      xhr.error = function ajaxError() {
        reject(new NetworkError(data, xhr));
      };
      xhr.abort = function ajaxAbort() {
        reject(new NetworkError(data, xhr));
      };

      let body = '';
      const CSRF_TOKEN_NAME = 'CSRF_TOKEN';
      if (isForm) {
        xhr.open(type, url, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
        body = this.stringifyData(data);
        body += body ? '&' : '';
        body += `${CSRF_TOKEN_NAME}=${new Settings().csrfToken}`;
      } else {
        if (type === 'GET') {
          const queryString = this.stringifyData(data);
          url = queryString ? `${url}?${queryString}` : url;
        } else {
          body = JSON.stringify(data);
        }
        xhr.open(type, url, true);
        xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
      }

      xhr.setRequestHeader('Accept', 'application/json; charset=utf-8');
      xhr.setRequestHeader('X-Requested-With', 'XmlHttpRequest');
      if ((/^(POST|PUT|DELETE)$/.test(type))) {
        xhr.setRequestHeader(CSRF_TOKEN_NAME, new Settings().csrfToken);
      }
      xhr.timeout = timeout || 0;
      xhr.send(body);
    });
  }

  /**
     * @param {Array.<object>} collection
     * @param {string} key
     * @returns {Object.<string, Array.<object>>}
     */
  groupBy(collection, key) {
    return collection.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  /**
     * @param {string} fileName
     * @param {string} logMessage
     */
  logDebugMessage(fileName, logMessage) {
    this.postLoggingMessage(new Settings().logLevel.DEBUG, fileName, logMessage);
  }

  /**
     * @param {string} fileName
     * @param {string} logMessage
     */
  logInfoMessage(fileName, logMessage) {
    this.postLoggingMessage(new Settings().logLevel.INFO, fileName, logMessage);
  }

  /**
     * @param {string} fileName
     * @param {string} logMessage
     */
  logWarningMessage(fileName, logMessage) {
    this.postLoggingMessage(new Settings().logLevel.WARN, fileName, logMessage);
  }

  /**
     * @param {string} fileName
     * @param {string} logMessage
     */
  logErrorMessage(fileName, logMessage) {
    this.postLoggingMessage(new Settings().logLevel.ERROR, fileName, logMessage);
  }

  /**
     * @param {LIQUID.actions.logLevel} logLevel
     * @param {string} fileName - this is the last portion of a segment of the log name on the server
     * @param {string} logMessage - the message to log
     */
  postLoggingMessage(logLevel, fileName, logMessage) {
    const logJson = {
      logLevel,
      message: logMessage,
      fileName,
      location: location.href,
      environment: navigator.userAgent,
    };

    this.post('/apps/shopping/clientLogger/logMessage', logJson);
  }

  stringifyData(obj) {
    return obj ? Object.keys(obj).sort().map((key) => {
      const val = obj[key];

      if (val === undefined) {
        return '';
      }

      if (val === null) {
        return encodeURIComponent(key);
      }

      if (Array.isArray(val)) {
        const result = [];

        val.slice().forEach((val2) => {
          if (val2 === undefined) {
            return;
          }

          if (val2 === null) {
            result.push(encodeURIComponent(key));
          } else {
            result.push(`${encodeURIComponent(key)}=${encodeURIComponent(val2)}`);
          }
        });

        return result.join('&');
      }

      return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
    }).filter((x) => x.length > 0)
      .join('&') : '';
  }
}
