import Settings from '../settings';
import utils from '../utils';

export default class Base {
  /**
     * @constructor
     * @param {object} o
     */
  constructor(o = {}) {
    this.setProperties(o); // TODO: ideally, we shouldn't have to do this, everything should be explicitly assigned
    this._computedProperties = [];
  }

  /**
     * @return {number}
     */
  getId() {
    return this._id;
  }

  /**
     * @param {object} o
     * @param {string} propName
     * @param {Function} Cls
     */
  deserializeProperty(o, propName, Cls) {
    if (o[propName]) {
      this[propName] = new Cls(o[propName]);
    }
  }

  /**
     * @param {object} o
     * @param {string} propName
     * @param {Function} Cls
     */
  deserializeArray(o, propName, Cls) {
    let a = [];
    if (o[propName]) {
      a = o[propName].map((item) => new Cls(item));
    }
    this[propName] = a;
  }

  /**
     * @param {object} o
     * @param {string} propName
     * @param {Function} Cls
     * @param {Function} ACls
     */
  deserializeMap(o, propName, Cls, ACls) {
    const a = {};
    if (o[propName]) {
      Object.keys(o[propName]).forEach((key) => {
        if (Cls === Array) {
          const list = o[propName][key].map((item) => new ACls(item));
          a[key] = list;
        } else {
          a[key] = new Cls(o[propName][key]);
        }
      });
    }
    this[propName] = a;
  }

  /**
     * @param {string} key
     * @param {*} val
     */
  setComputedProperty(key, val) {
    this._computedProperties.push(key);
    this[key] = val;
  }

  /**
     * return {object}
     */
  serializeProperties() {
    function getValue(item) {
      return item && item.serializeProperties ? item.serializeProperties() : item;
    }

    const o = {};
    for (const key of Object.keys(this)) {
      // _ prefixed things are internal objects for the model, __ prefixed things are ember internal objects of ember
      if (
        this._computedProperties.indexOf(key) === -1
                && typeof this[key] !== 'function'
                && key.indexOf('_') !== 0
      ) {
        if (Array.isArray(this[key])) {
          o[key] = this[key].map((item) => getValue(item));
        } else {
          o[key] = getValue(this[key]);
        }
      }
    }
    return o;
  }

  /**
     * @override
     * return {object}
     */
  serialize() {
    return utils.deepCopy(this);
  }

  /**
     * @override
     * @param {object} o
     */
  setProperties(o) {
    for (const key in o) {
      this[key] = o[key];
    }
  }
}
