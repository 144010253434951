(function(exports) {
    'use strict';

    /**
     * @class
     * @constructor
     */
    var Cookies = function() {
    };

    /**
     * Create a cookie
     * @param name {string} Cookie name
     * @param value {string|object} Cookie value
     * @param [hours] {object} Duration in hours. Defaults to the duration of the session
     * @static
     */
    Cookies.create = function(name, value, hours) {
        var expires = '';
        var date = new Date();

        try {
            if (typeof value === 'object') {
                value = JSON.stringify(value);
            }
        } catch (e) {}

        if (hours) {
            date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
            expires = '; expires=' + date.toGMTString();
        }

        document.cookie = name + '=' + encodeURIComponent(value) + expires + '; path=/';
    };

    /**
     * Read a cookie
     * @param name {string} Cookie name
     * @return {string|object} Cookie value
     * @static
     */
    Cookies.read = function(name) {
        var nameEQ = name + '=';
        var ca = document.cookie.split(';');
        var c;
        for (var i = 0; i < ca.length; i++) {
            c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                var value = decodeURIComponent(c.substring(nameEQ.length, c.length));
                try {
                    value = JSON.parse(value);
                } catch (e) {}

                return value;
            }
        }
        return null;
    };

    /**
     * Erase a cookie
     * @param name {string} Cookie name
     * @static
     */
    Cookies.erase = function(name) {
        this.create(name, "", -1);
    };

    /**
     * Erase all cookies
     * @static
     */
    Cookies.eraseAll = function() {
        var ca = document.cookie.split(';');
        ca.each(function(c) {
            var name = c.strip().split('=')[0];
            Cookies.erase(name);
        });
    };

    exports.Cookies = Cookies;

}(window));
