export default {
  deepCopy(oo) {
    function recursiveDeepCopy(o) {
      let newObj;
      let i;

      if (typeof o !== 'object') {
        return o;
      }
      if (!o) {
        return o;
      }

      if (Object.prototype.toString.apply(o) === '[object Array]') {
        newObj = [];
        for (i = 0; i < o.length; i += 1) {
          newObj[i] = recursiveDeepCopy(o[i]);
        }
        return newObj;
      }

      newObj = {};
      for (const key of Object.keys(o)) {
        newObj[key] = recursiveDeepCopy(o[key]);
      }
      return newObj;
    }

    return recursiveDeepCopy(oo);
  },
};
