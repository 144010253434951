/* eslint-disable no-param-reassign */
let instance = null;
const API_METHOD_PREFIX = 'api';
export default class Settings {
    constructor() {
        // settings is re-constructed in multiple places.  This creates a singleton so all creators get the same object.
        if (instance !== null) {
            return instance;
        }

        this.logLevel = {
            DEBUG: 'debug',
            INFO: 'info',
            WARN: 'warn',
            ERROR: 'error',
        };

        this.customLog = {
            /**
             * used for recording session steps to log on error
             * @type {Array.<object>}
             */
            actions: [],

            /**
             * used for same as actions
             * @type {Array.<object>}
             */
            innerStack: [],

            /**
             * request is recorded when there is a http request error
             */
            request: null,
        };

        // PAGE_DATA.platforms[0].webDevMode : passed through application.js, setup.js, and set in index.js
        this.webDevMode = false;

        // Deprecated.  Please use actions/stores to publish/subscribe to events.
        // https://gitlab.corp.switchfly.com/switchfly/dev/merge_requests/14214#note_1249851
        this.events = {};

        instance = this;
        return instance;
    }

    setDefaultErrorMessage(message = '') {
        this.defaultError = message;
    }

    /**
     * @param {Promise} promiseImpl
     */
    setPromiseImpl(promiseImpl) {
        this.Promise = promiseImpl;
    }

    setCsrfToken(csrfToken = '') {
        this.csrfToken = csrfToken;
    }

    /**
     * call this on app load if you want actions server logging, etc.
     */
    setupActionUI(models = {}, actions = {}, stores = {}) {
        Object.entries({actions, models, stores}).forEach(([typeKey, typ]) => {
            for (const cls in typ) {
                if (cls === 'Promise') {
                    continue;
                } // not sure why we skip this.
                const proto = typ[cls].prototype;
                if (!proto) {
                    console.log(`liquid warn: this is not a valid ${typeKey.slice(0, -1)} > ${cls}`);
                    continue;
                }

                Object.getOwnPropertyNames(proto).forEach((method) => {
                    // silently ignore non functions since they can't produce errors by themselves.
                    if (typeof proto[method] === 'function') {
                        (typeKey === 'actions' && method.startsWith(API_METHOD_PREFIX))
                            ? this.interceptActionUI(typ, cls, method)
                            : this.interceptToLog(typ, typeKey, cls, method);
                    }
                });
            }
        });
    }

    interceptToLog(collection = {}, entity = '', cls = '', method = '') {
        const old = collection[cls].prototype[method];
        const settings = this;
        collection[cls].prototype[method] = function () {
            settings.prependToLimitedStack(settings.customLog.innerStack, `${entity}:${cls}#${method}`);
            return old.apply(this, arguments);
        };
    }

    interceptActionUI(actions = {}, cls = '', method = '') {
        const settings = this;
        const old = actions[cls].prototype[method];

        /**
         * wrap the class method to catch and log thrown errors
         * @return {LIQUID.actions.Promise}
         */
        actions[cls].prototype[method] = function () {
            if (settings.webDevMode) {
                console.log(`----- action: ${cls}#${method} -----`);
            }

            settings.prependToLimitedStack(settings.customLog.actions, `${cls}#${method}`);
            settings.customLog.innerStack.length = 0; // clear

            const logError = (error = {}) => {
                if (typeof error === 'object' && error !== null && !Array.isArray(error)) {
                    if (!(error instanceof Error)) {
                        // new Error() for true stack trace
                        // Object.assign to capture properties on passed objects, like request and response on network objects
                        error = Object.assign(new Error(), error, {message: error.message || JSON.stringify(error)});
                    }
                    // else do nothing to retain the original stack
                    settings.customLog.request = error.request || null;
                } else {
                    error = new Error(JSON.stringify(error));
                }

                // try because we don't trust onError to not throw, but don't need to log it in production if it does.
                if (settings.events) {
                    try { settings.events.onError(error); } catch (e) { (console.error || console.log)(e); }
                }

                (console.error || console.log)(error);

                const {customLog} = settings;

                // console log complete error data on dev mode for debugging purposes.
                if (settings.webDevMode) {
                    console.log(JSON.stringify(customLog, null, 4));
                }

                const customLogStr = JSON.stringify(customLog, null, 4);

                // "this" here references the base action class, assuming the prototype chain never changes, which is unlikely.
                // The outcome would be errors that never get logged, but that's too big an issue to solve in this bug.
                this.logErrorMessage('UIAction', `[type=LiquidError] | ${error.stack || error.valueOf()} | ${customLogStr}`);

                throw error;
            };

            // catch errors in constructors and any other functions that execute code outside of a promise.
            try {
                const result = old.apply(this, arguments);
                // if the function returns a promise, execute its catch method
                // Note that functions with catch blocks that don't re-throw their errors will never reach this catch()
                return (result && typeof result.catch === 'function') ? result.catch(logError) : result;
            } catch (e) {
                logError(e);
                throw e;
            }
        };
    }

    // Limits the stack size to 5 items
    prependToLimitedStack(stack = [], newItem = '') {
        if (stack.length > 4) {
            stack.length = 4;
        }
        return stack.unshift(newItem);
        // Another option to the above is to use push, and then when logging, sort in reverse (a = a[::-1]), but then you can't just set length.
        // That will require splice, and splice is more expensive than un/shift.  See: https://stackoverflow.com/a/10742428
        // So, for now, leaving it as above.
        // At least the unshift now occurs with a max of 4 existing items.
    }
}
