// import '@babel/polyfill';
import ObjectStore from './stores/object-store';
import ClassStore from './stores/class-store';
import SessiontStorageStore from './stores/session-storage-store';

import LiquidError from './errors/liquid-error';
import NetworkError from './errors/network-error';
import KnownServerError from './errors/known-server-error';
import TimeOutError from './errors/time-out-error';

import ActionBase from './actions/base';
import ModelBase from './models/base';

import Settings from './settings';

import _utils from './utils';

export function init(options = {}) {
  const settings = new Settings();

  settings.webDevMode = options.webDevMode;

  settings.setDefaultErrorMessage(options.defaultErrorMessage);
  if (options.promiseImpl) {
    settings.setPromiseImpl(options.promiseImpl); // force to use external promise
  }
  settings.setCsrfToken(options.csrfToken);

  // deprecated. please use actions/stores to subscribe to events.
  settings.events = options.events;

  if (options.serverLogging === true) {
    // if (LIQUID.DEBUG_LEVEL === 1 && performance) {
    // const t0 = performance.now();
    // }

    settings.setupActionUI(options.models, options.actions, options.stores);

    // if (LIQUID.DEBUG_LEVEL === 1 && performance) {
    // const t1 = performance.now();
    // console.log('setupActionUI: ' + (t1 - t0) + ' milliseconds.');
    // }
  }
}

export const models = {
  Base: ModelBase,
};

export const stores = {
  ObjectStore,
  ClassStore,
  SessionStorageStore: SessiontStorageStore,
};

export const actions = {
  Base: ActionBase,
};

export const errors = {
  LiquidError,
  NetworkError,
  KnownServerError,
  TimeOutError,
};

export const utils = _utils;
